'use client';

import { Fragment, useContext, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import dayjs from 'dayjs';

import { Context } from './Context';

import { EventItem } from './ListItem';

import { PropsWithClasses } from '@lamesarv-sdk/types';
import { IEvent } from '@/types/cms';

// --

type EventBlock = {
  [key: string]: IEvent[];
};

export const ListBase = (props: PropsWithClasses) => {
  const { context } = useContext(Context);

  const events = useMemo(() => {
    if (!context?.events?.length || !context?.metadata) return [];

    const _perPage = context?.metadata?.perPage ?? 4;
    const _start = ((context?.metadata?.currentPage ?? 1) - 1) * _perPage;
    const _end = (context?.metadata?.currentPage ?? 1) * _perPage;

    const _events = context.events.slice(_start, _end);

    if (!_events.length) return [];

    return _events;
  }, [context?.events, context?.metadata]);

  const eventsBlocks: EventBlock = {};

  events.forEach((event) => {
    const startDate = dayjs(event.startDate);
    const key = startDate.format('MMMM YYYY');

    if (!eventsBlocks[key]) eventsBlocks[key] = [];

    eventsBlocks[key].push(event);
  });

  return (
    <div className={twMerge('state', props.className)}>
      {!events?.length && (
        <div className="flex flex-row justify-center items-center">
          <span className="text-gray-300">No events found</span>
        </div>
      )}
      {Object.entries(eventsBlocks).map(([key, events]) => (
        <Fragment key={key}>
          <div className="relative pt-8 mb-8 border-b border-gray-200">
            <span className="absolute text-sm font-medium p-4 bg-white left-0 bottom-0 translate-y-1/2">{key}</span>
          </div>
          {events.map((item) => (
            <EventItem key={item.slug} {...item} />
          ))}
        </Fragment>
      ))}
    </div>
  );
};
