import { EventsReducerActionType, IEventsContextData, IEventsReducerAction } from './Types';

export const eventsReducer = (state: IEventsContextData, action: IEventsReducerAction) => {
  switch (action.type) {
    case EventsReducerActionType.updateEventsDatesBase:
      return {
        ...state,
        eventsDatesBase: action.payload,
      };
    case EventsReducerActionType.updateEvents:
      return {
        ...state,
        events: action.payload,
      };
    case EventsReducerActionType.updateQuery:
      return {
        ...state,
        filters: {
          ...(state?.filters ?? {}),
          query: action.payload,
        },
      };
    case EventsReducerActionType.updateStates:
      return {
        ...state,
        filters: {
          ...(state?.filters ?? {}),
          states: action.payload,
        },
      };
    case EventsReducerActionType.updateCities:
      return {
        ...state,
        filters: {
          ...(state?.filters ?? {}),
          cities: action.payload,
        },
      };
    case EventsReducerActionType.updateVenues:
      return {
        ...state,
        filters: {
          ...(state?.filters ?? {}),
          venues: action.payload,
        },
      };
    case EventsReducerActionType.updateDates:
      return {
        ...state,
        filters: {
          ...(state?.filters ?? {}),
          startDate: action.payload.startDate,
          endDate: action.payload.endDate,
          statusNavigation: action.payload.statusNavigation,
        },
      };
    case EventsReducerActionType.updateFilters:
      return {
        ...state,
        filters: {
          ...(state?.filters ?? {}),
          ...action.payload,
        },
      };
    case EventsReducerActionType.updateMetadata:
      return {
        ...state,
        metadata: {
          ...(state?.metadata ?? {}),
          ...action.payload,
        },
      };
    case EventsReducerActionType.clear:
      return {};
    default:
      return state;
  }
};
