import dayjs from 'dayjs';

import { IButterEvent, IButterVenue, IEvent, IVenue } from '@/types/cms';
import { getFacetCode } from '@lamesarv-sdk/tools';

export const parseVenue = (venue: IButterVenue): IVenue => {
  return {
    title: venue.title,
    slug: venue.slug,
    descriptionSEO: venue.description_seo,
    features: venue.features,
    image: venue.image,
    city: venue.city,
    state: venue.state,
    zipCode: venue.zip_code,
    address: venue.address,
    map: venue.map,
    mapEmbedded: venue.map_embedded,
    headerClasses: venue.header_classes,
    headerBackgroundUrl: venue.header_image_url,
  };
};

export const parseEvent = (event: IButterEvent): IEvent => {
  return {
    title: event.title,
    slug: event.slug,
    descriptionSEO: event.description_seo,
    description: event.description,
    image: event.image_url,
    startDate: event.start_date,
    closedDate: event.closed_date,
    endDate: event.end_date,
    startTime: event.start_time,
    endTime: event.end_time,
    cost: event.cost,
    venue: parseVenue(event.venue),
    headerClasses: event.header_classes,
    headerBackgroundUrl: event.header_image_url,
  };
};

export const searchEvents = (
  events: IEvent[],
  query: string,
  states: string[],
  cities: string[],
  venues: string[],
): IEvent[] => {
  const eventFields = ['title', 'slug', 'descriptionSEO'];
  const venueFields = ['title', 'slug', 'descriptionSEO', 'city', 'state', 'zipCode'];

  const matchesQuery = (field: string, query: string) => {
    return field.toLowerCase().includes(query.toLowerCase());
  };

  return events.filter((event) => {
    if (states.length > 0 && !states.includes(getFacetCode(event.venue.state))) return false;
    if (cities.length > 0 && !cities.includes(getFacetCode(event.venue.city))) return false;
    if (venues.length > 0 && !venues.includes(getFacetCode(event.venue.title))) return false;

    if (!query) return true;

    const eventMatch = eventFields.some((field) => matchesQuery(event[field], query));
    const venueMatch = venueFields.some((field) => matchesQuery(event.venue[field], query));

    return eventMatch || venueMatch;
  });
};

export const searchVenues = (venues: IVenue[], query: string, states: string[], cities: string[]): IVenue[] => {
  const venueFields = ['title', 'slug', 'descriptionSEO', 'city', 'state', 'zipCode'];

  const matchesQuery = (field: string, query: string) => {
    return field.toLowerCase().includes(query.toLowerCase());
  };

  return venues.filter((venue) => {
    if (states.length > 0 && !states.includes(getFacetCode(venue.state))) return false;
    if (cities.length > 0 && !cities.includes(getFacetCode(venue.city))) return false;

    if (!query) return true;

    const venueMatch = venueFields.some((field) => matchesQuery(venue[field], query));

    return venueMatch;
  });
};

export const getLocaleTimeString = (timeBase: string) => {
  const [hoursBase, minutesBase, secondsBase] = timeBase.split(':');

  const hours = parseInt(hoursBase, 10);
  const minutes = parseInt(minutesBase, 10);
  const seconds = secondsBase ? parseInt(secondsBase, 10) : 0;

  const date = dayjs().hour(hours).minute(minutes).second(seconds);

  return date.format('hh:mm a');
};

export const getNavigationDates = (dateBase?: string) => {
  const startDate = dateBase ? dayjs(dateBase) : dayjs();
  const endDate = startDate.add(1, 'month').endOf('month');

  return { startDate, endDate };
};
