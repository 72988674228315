'use client';

import { useContext, useEffect } from 'react';
import dayjs from 'dayjs';

import { Context } from './Context';
import { useGetEvents } from '@/hooks/events';

import { EventsReducerActionType } from './Types';

export interface IHandlerProps {
  displayEventsAmount?: number;
}

export const Handler = (props: IHandlerProps) => {
  const { context, dispatch } = useContext(Context);
  const { fetchItems: getEvents } = useGetEvents({ skipFetch: true });

  const getItems = async () => {
    const dateNow = dayjs();

    const events = await getEvents({
      ...(context?.filters ?? {}),
      validDate: !context?.filters?.startDate && !context?.filters?.endDate,
      sort: context?.filters?.expired ? '-start_date' : 'start_date',
    });

    events.forEach((event) => {
      event.isExpired = dateNow.isAfter(dayjs(event.endDate));
    });

    dispatch({
      type: EventsReducerActionType.updateEvents,
      payload: events,
    });

    if (!context?.eventsDatesBase) {
      dispatch({
        type: EventsReducerActionType.updateEventsDatesBase,
        payload: events.map((event) => event.startDate),
      });
    }

    // --

    const metadata = {
      nbHits: events.length,
      nbPages: Math.ceil(events.length / (props.displayEventsAmount ?? 4)),
      currentPage: 1,
      perPage: props.displayEventsAmount ?? 4,
    };

    dispatch({
      type: EventsReducerActionType.updateMetadata,
      payload: metadata,
    });
  };

  useEffect(() => {
    getItems();
  }, [context?.filters]);

  return null;
};
