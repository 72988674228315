'use client';

import { createContext, Dispatch, PropsWithChildren, useMemo, useReducer } from 'react';

import { eventsReducer } from './Reducer';
import { IEventsContextData, IEventsReducerAction } from './Types';
import { getFacetCode } from '@lamesarv-sdk/tools';

export interface IEventContext {
  context: IEventsContextData;
  dispatch: Dispatch<IEventsReducerAction>;
  getStatesList: () => string[];
  getCitiesList: (states: string[]) => string[];
  getVenuesList: (states: string[], cities?: string[]) => string[];
}

export const Context = createContext<IEventContext>({} as IEventContext);

export const Provider = ({ children, ...props }: PropsWithChildren<IEventsContextData>) => {
  const [context, dispatch] = useReducer(eventsReducer, props);

  const getStatesList = () => {
    return Array.from(new Set(context?.venues?.map((venue) => venue.state)));
  };

  const getCitiesList = (states: string[]) => {
    return Array.from(
      new Set(
        context?.venues?.filter((venue) => states.includes(getFacetCode(venue.state)))?.map((venue) => venue.city),
      ),
    );
  };

  const getVenuesList = (states: string[], cities?: string[]) => {
    return Array.from(
      new Set(
        context?.venues
          ?.filter(
            (venue) =>
              states.includes(getFacetCode(venue.state)) &&
              (!cities.length || cities.includes(getFacetCode(venue.city))),
          )
          ?.map((venue) => venue.title),
      ),
    );
  };

  const providerValue = useMemo(
    () => ({ context, dispatch, getStatesList, getCitiesList, getVenuesList }),
    [context, dispatch, getStatesList, getCitiesList, getVenuesList],
  );

  return <Context.Provider value={providerValue}>{children}</Context.Provider>;
};
