'use client';

import { faTicketPerforated } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { twMerge } from 'tailwind-merge';
import dayjs from 'dayjs';

import { GenericHtml } from '@lamesarv-sdk/components';
import { getLocaleTimeString } from '@/tools/events';

import { IEvent } from '@/types/cms';

export const EventItem = (props: IEvent) => {
  const startDate = dayjs(props.startDate);
  const endDate = dayjs(props.endDate);
  const closedDate = dayjs(props.closedDate);

  const startTime = getLocaleTimeString(props.startTime);
  const endTime = getLocaleTimeString(props.endTime);

  return (
    <a
      href={`/shows/${props.slug}`}
      className={twMerge(
        'flex flex-col gap-4 lg:gap-10 items-start rounded-sm p-6 lg:flex-row hover:shadow-md',
        props.isExpired && 'grayscale',
      )}
    >
      <div className="hidden lg:flex lg:flex-none">
        <div className="flex flex-row gap-2 items-center">
          <div className="flex flex-col items-center gap-1">
            <div className="text-xs uppercase leading-none">{startDate.format('ddd')}</div>
            <div className="text-2xl font-bold leading-none">{startDate.format('DD')}</div>
          </div>
          <div className="flex flex-col">-</div>
          <div className="flex flex-col items-center gap-1">
            <div className="text-xs uppercase leading-none">{endDate.format('ddd')}</div>
            <div className="text-2xl font-bold leading-none">{endDate.format('DD')}</div>
          </div>
        </div>
      </div>
      <div className="flex-none w-full lg:w-96">
        <img src={props.image} alt="Event Image" className="w-full h-auto object-cover rounded-sm" />
      </div>
      <div className="grow flex flex-col gap-3">
        <div className="flex flex-row gap-2 items-center">
          <span className="text-sm">{`@ ${startTime} - ${endTime}`}</span>
          {props.closedDate && (
            <span className="text-sm font-medium text-red-600">
              (Event Closed on {closedDate.format('ddd')}, {closedDate.format('MMM DD')})
            </span>
          )}
        </div>
        <div className="flex flex-row gap-2 items-center">
          <h2 className="text-xl text-black font-bold">{props.title}</h2>
        </div>
        <div className="flex flex-row gap-2 items-center">
          <span className="text-sm font-bold">
            {props.venue.city}, {props.venue.state}
          </span>
          <span className="text-sm text-gray-500">
            {props.venue.address}, {props.venue.city}, {props.venue.state}, {props.venue.zipCode}
          </span>
        </div>
        <div className="flex flex-row gap-2 items-center">
          <span className="text-sm font-medium">{startDate.format('ddd, MMM DD')}</span>
          <span className="text-sm font-medium">-</span>
          <span className="text-sm font-medium">{endDate.format('ddd, MMM DD')}</span>
          <span className="text-sm text-gray-500">({endDate.diff(startDate, 'days')} days)</span>
        </div>
        <div className="flex flex-row gap-2 items-center">
          <GenericHtml content={props.description} />
        </div>
        <div className="flex flex-row gap-2 items-center">
          <FontAwesomeIcon icon={faTicketPerforated} className="w-6 h-6 -rotate-45" />
          {!!props.cost && (
            <span className="uppercase text-black font-bold leading-none">${props.cost.toLocaleString()}</span>
          )}
          {!props.cost && <span className="uppercase text-black font-bold leading-none">Free</span>}
        </div>
      </div>
    </a>
  );
};
