'use client';

import { useContext } from 'react';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';

import { Context } from './Context';

import { Button, ButtonType } from '@lamesarv-sdk/components';

import { EventsReducerActionType } from './Types';

export const NavigationFooter = () => {
  const { context, dispatch } = useContext(Context);

  const handleNavigateBackward = () => {
    if (context?.metadata?.currentPage === 1) {
      dispatch({
        type: EventsReducerActionType.updateFilters,
        payload: {
          startDate: '',
          endDate: dayjs().toString(),
          expired: true,
        },
      });

      return;
    }

    dispatch({
      type: EventsReducerActionType.updateMetadata,
      payload: {
        currentPage: (context?.metadata?.currentPage ?? 1) - 1,
      },
    });
  };

  const handleNavigateForward = () => {
    if (context?.metadata?.currentPage === 1 && context?.filters.expired) {
      dispatch({
        type: EventsReducerActionType.updateFilters,
        payload: {
          startDate: '',
          endDate: '',
          expired: false,
        },
      });

      return;
    }

    dispatch({
      type: EventsReducerActionType.updateMetadata,
      payload: {
        currentPage: (context?.metadata?.currentPage ?? 1) + 1,
      },
    });
  };

  return (
    <div className="flex flex-row items-center justify-between">
      <Button
        type={ButtonType.flat}
        title="Previous Shows"
        icon={<FontAwesomeIcon icon={faChevronLeft} className="w-4 h-4" />}
        className="p-2 rounded-sm"
        onClick={() => handleNavigateBackward()}
        disabled={context?.filters?.expired}
      />
      <Button
        type={ButtonType.flat}
        title="Next Shows"
        icon={<FontAwesomeIcon icon={faChevronRight} className="w-4 h-4" />}
        className="p-2 rounded-sm"
        onClick={() => handleNavigateForward()}
        disabled={context?.metadata?.currentPage === context?.metadata?.nbPages}
      />
    </div>
  );
};
