'use client';

import { Fragment, useContext, useEffect, useState } from 'react';
import { faChevronDown, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { twMerge } from 'tailwind-merge';
import dayjs from 'dayjs';

import { DayPicker } from 'react-day-picker';
import 'react-day-picker/style.css';

import { Context } from './Context';

import { Button, ButtonType } from '@lamesarv-sdk/components';

import { getNavigationDates } from '@/tools/events';
import { EventsReducerActionType } from './Types';

export type IEventFilter = { label: string; value: string };

export interface IEventDates {
  startDate: dayjs.Dayjs;
  endDate: dayjs.Dayjs;
}

// --

export const NavigationCalendar = () => {
  const { context, dispatch } = useContext(Context);

  const handleSelectDate = (date: Date) => {
    const { startDate, endDate } = getNavigationDates(date.toDateString());

    dispatch({
      type: EventsReducerActionType.updateFilters,
      payload: {
        startDate: startDate.toString(),
        endDate: endDate.toString(),
        expired: false,
      },
    });
  };

  return (
    <Popover className="md:relative">
      {({ close }) => (
        <Fragment>
          <PopoverButton className="">
            <div className="">
              <FontAwesomeIcon icon={faChevronDown} className="w-4 h-4" />
            </div>
          </PopoverButton>
          <PopoverPanel
            className={twMerge(
              'absolute left-1/2 -translate-x-1/2 mt-5 p-4 bg-white border border-gray-200 shadow-xl rounded-sm focus:outline-none z-50',
              'md:origin-top-right md:left-auto md:-right-7 md:w-fit md:min-w-full md:translate-x-0',
              'transition data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in',
            )}
          >
            <DayPicker
              mode="multiple"
              selected={context?.eventsDatesBase?.map((eventDate) => dayjs(eventDate).toDate())}
              startMonth={dayjs().startOf('month').toDate()}
              classNames={{
                day: 'hover:bg-gray-100',
                today: 'bg-gray-100',
                selected: '!bg-gray-900 border-2 border-white text-white font-bold',
              }}
              onDayClick={(day) => {
                handleSelectDate(day);
                close();
              }}
              onSelect={() => {}}
            />
          </PopoverPanel>
        </Fragment>
      )}
    </Popover>
  );
};

export const Navigation = () => {
  const { context, dispatch } = useContext(Context);
  const [navigationDates, setNavigationDates] = useState<IEventDates>({} as IEventDates);

  const handleNavigateBackward = () => {
    if (context?.metadata?.currentPage === 1) {
      dispatch({
        type: EventsReducerActionType.updateFilters,
        payload: {
          startDate: '',
          endDate: dayjs().toString(),
          expired: true,
        },
      });

      return;
    }

    dispatch({
      type: EventsReducerActionType.updateMetadata,
      payload: {
        currentPage: (context?.metadata?.currentPage ?? 1) - 1,
      },
    });
  };

  const handleNavigateForward = () => {
    if (context?.metadata?.currentPage === 1 && context?.filters.expired) {
      dispatch({
        type: EventsReducerActionType.updateFilters,
        payload: {
          startDate: '',
          endDate: '',
          expired: false,
        },
      });

      return;
    }

    dispatch({
      type: EventsReducerActionType.updateMetadata,
      payload: {
        currentPage: (context?.metadata?.currentPage ?? 1) + 1,
      },
    });
  };

  useEffect(() => {
    if (!context?.events?.length || !context?.metadata) return;

    const _perPage = context?.metadata?.perPage ?? 4;
    const _start = ((context?.metadata?.currentPage ?? 1) - 1) * _perPage;
    const _end = (context?.metadata?.currentPage ?? 1) * _perPage;

    const events = context.events.slice(_start, _end);

    if (!events.length) return;

    const firstEvent = events[0];
    const lastEvent = events[events.length - 1];

    setNavigationDates({
      startDate: dayjs(firstEvent.startDate),
      endDate: dayjs(lastEvent.endDate),
    });
  }, [context?.events, context?.metadata]);

  return (
    <Fragment>
      {context.filters.expired && (
        <div className="flex flex-row gap-1 items-center">
          <div className="font-medium mx-2">Previous Shows</div>
          <Button
            type={ButtonType.flat}
            icon={<FontAwesomeIcon icon={faChevronRight} className="w-4 h-4" />}
            className="p-2 rounded-sm"
            onClick={() => handleNavigateForward()}
          />
        </div>
      )}
      {!context.filters.expired && (
        <div className="flex flex-row gap-1 items-center">
          <Button
            type={ButtonType.flat}
            icon={<FontAwesomeIcon icon={faChevronLeft} className="w-4 h-4" />}
            className="p-2 rounded-sm"
            onClick={() => handleNavigateBackward()}
          />
          <Button
            type={ButtonType.flat}
            icon={<FontAwesomeIcon icon={faChevronRight} className="w-4 h-4" />}
            className="p-2 rounded-sm"
            onClick={() => handleNavigateForward()}
            disabled={context?.metadata?.currentPage === context?.metadata?.nbPages}
          />
          <div className="font-medium mx-2 md:hidden">
            {navigationDates?.startDate?.format('MMM DD')}
            {' - '}
            {navigationDates?.endDate?.format('MMM DD')}
          </div>
          <div className="font-medium mx-2 hidden md:block">
            {navigationDates?.startDate?.format('MMMM DD')}
            {' - '}
            {navigationDates?.endDate?.format('MMMM DD')}
          </div>
          <NavigationCalendar />
        </div>
      )}
    </Fragment>
  );
};
