/* eslint-disable @typescript-eslint/no-explicit-any */

import { IEvent } from '@/types/cms';

export enum EventNavigationStatus {
  expired = 'expired',
  active = 'active',
}
export interface IEventsFilters {
  query?: string;
  states?: string[];
  cities?: string[];
  venues?: string[];
  startDate?: string;
  endDate?: string;
  expired?: boolean;
}

export interface IVenues {
  title: string;
  state: string;
  city: string;
}

export interface IMetadata {
  nbHits: number;
  nbPages: number;
  currentPage: number;
  perPage: number;
}

// -- Context

export interface IEventsContextData {
  eventsDatesBase?: string[];
  events?: IEvent[];
  venues?: IVenues[];
  filters?: IEventsFilters;
  metadata?: IMetadata;
}

export enum EventsReducerActionType {
  updateCities = 'updateCities',
  updateDates = 'updateDates',
  updateEventsDatesBase = 'updateEventsDatesBase',
  updateEvents = 'updateEvents',
  updateFilters = 'updateFilters',
  updateMetadata = 'updateMetadata',
  updateQuery = 'updateQuery',
  updateStates = 'updateStates',
  updateVenues = 'updateVenues',
  clear = 'clear',
}

export interface IEventsReducerAction {
  type: EventsReducerActionType;
  payload?: any;
}
